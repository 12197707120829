import React, {PropsWithChildren, useEffect, useState, useRef} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import 'react-loading-skeleton/dist/skeleton.css';
import {useTranslation} from "react-i18next";
import "../assets/partners.scss"

const Partners = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [openMarket, setOpenMarket] = useState([]);
  const [generalMarket, setGeneralMaret] = useState([]);
  const [specialtyMarket, setSpecialtyMarket] = useState([]);
  const [selectedCate, setSelectedCate] = useState("");

  const ref = useRef<any>(null)
  const category = {1: "clothes", 2:"fashionAcc", 3: "shoes", 4: "jewerly",
    5: "sports", 6: "underwear", 7: "household", 8: "food",
    9: "beauty",10: "interior", 11: "appliance", 12: "pet",
    13: "kidsClothes", 14: "kidsToy", 15: "books",16: "custom"}

  function mallSkeleton({ children }: PropsWithChildren<unknown>) {
    return (
      <div
        style={{
          display: 'block',
          lineHeight: 2,
          padding: '1rem',
          marginBottom: '0.5rem'
        }}
      >
        {children}
      </div>
    )
  }

  const getServiceInfo = async (num : any, name: string) => {
  try {
    const { data, status } = await axios.get(`${process.env.REACT_APP_PROXY}/homepage/categorys/${num}`);
    if( status === 200 ) {

      setLoading(true);

      setTimeout(() => {
        setSelectedCate(name)
        // console.log("name : ",name);
        setLoading(false);
        setOpenMarket(data.shop_malls?.open);
        setGeneralMaret(data.shop_malls?.general);
        setSpecialtyMarket(data.shop_malls?.specialty);
      }, 500)
    }
  }
  catch(error) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    error.response.data;
    setLoading(false);
  }
}
  useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
     getServiceInfo(1,"clothes")
      // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Helmet>
        <title>{t('pageTitle.partners')}{t('pageTitle.common')}</title>
      </Helmet>
      <div className="partners_container">
        <section className="main">
          <h2>
            매장별 운영 관리부터 <br className="screen_mobile"/>온라인 판매까지<br/>
            한번에 해결하세요.
          </h2>
          <picture>
            <source srcSet="/assets/images/partners_hero_m.png" media="(max-width: 768px)" />
            <source srcSet="/assets/images/partners_hero.png" media="(min-width: 769px)" />
            <img className="partners_hero" alt="partners_hero_img" src="/assets/images/partners_hero.png"/>
          </picture>
          <div className="type_wrapper">
            <div className="type shop">
              <img alt="partners_hero_shop" src="/assets/images/partners_hero_shop.png"/>
              <div className='text_wrapper'>
                <p className="title">
                  매장
                </p>
                <ul className="desc">
                  <li>
                    정보 입력만으로 최대 20여개 몰에 판매 노출 가능
                  </li>
                  <li>
                    상품 정보 등록만 하면 셀러허브 전담 MD가 판매 전 과정을 대행
                  </li>
                  <li>
                    모든 쇼핑몰의 주문을 한 번에 관리하고 배송 정보만 입력하면 주문 및 배송 관리 완료
                  </li>
                </ul>
              </div>
            </div>
            <div className='type partner'>
              <img alt="partners_hero_market" src="/assets/images/partners_hero_partner.png"/>
              <div className='text_wrapper'>
                <p className="title">
                  파트너사
                </p>
                <ul className="desc">
                  <li>
                    셀러허브 통합 관리 시스템을 통해 매장 및 점주 관리
                  </li>
                  <li>
                    지점 재고 및 반품 등 판매 관련 원 클릭 통합 관리
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="growth">
          <h2>
          이미 여러 파트너사들이 <br/>
          <span className="bold">폭발적인 온라인 매출 성장</span>을 <br className="screen_mobile"/>경험하고 있어요.
          </h2>
          <div className="logo-wrapper">
            <img alt="growth_partner_logo" src="/assets/images/main_partner_logo_11.png"/>
            <img alt="growth_partner_logo" src="/assets/images/main_partner_logo_8_2.png"/>
            <img alt="growth_partner_logo" src="/assets/images/main_partner_logo_12.png"/>
            <img alt="growth_partner_logo" src="/assets/images/main_partner_logo_13.png"/>
          </div>
          <div className="growth-wrapper">
            <div className="growth-item">
              <p className="title">
                수도권 대형 A 지점 매출 추이
              </p>
              <p className="desc">
                첫 해 매출 48억, 연평균 35% 성장하며<br/>
                2020년 매출 160억 달성
              </p>
              <img alt="graph" src="/assets/images/partners_growth_graph_01.png"/>
            </div>
            <div className="growth-item">
              <p className="title">
                서울 중형 B 지점 매출 추이
              </p>
              <p className="desc">
              입점 첫 해 매출 30억 달성, 1년 만에<br/>
              2배 이상 성장하며 연매출 85억 달성
              </p>
              <img alt="graph" src="/assets/images/partners_growth_graph_02.png"/>
            </div>
            <div className="growth-item">
              <p className="title">
                수도권 소형 C 지점 매출 추이
              </p>
              <p className="desc">
                입점 첫 해 매출 6.8억 달성, <br/>
                1년만에 8배 이상 성장하며 연매출 58억 달성
              </p>
              <img alt="graph" src="/assets/images/partners_growth_graph_03.png"/>
            </div>
          </div>
        </section>
        <section className='commission'>
          <h2>
            판매 대행 수수료는<br/>
            영업 담당자와 협의해요.
          </h2>
          <div className="img_wrapper">
          <picture>
            <source srcSet="/assets/images/partners_commission_m.png" media="(max-width: 768px)" />
            <source srcSet="/assets/images/partners_commission.png" media="(min-width: 769px)" />
            <img alt="commission" src="/assets/images/partners_commission.png" />
          </picture>
          </div>
          <div className="box_container">
              <div className="box1"></div>
              <div className="box2">파트너사</div>
              <div className="box3">매장</div>
              <div className="box4">수수료</div>
              <div className="box5">온라인 판매수수료</div>
              <div className="box6">운영대행 수수료</div>
              <div className="box7">파트너사와 협의 후 진행</div>
              <div className="box8">내용</div>
              <div className="box9">연동 가능한 모든 쇼핑몰 노출 (카테고리별 최대 20여개)</div>
              <div className="box10">기타</div>
              <div className="box11">파트너사(관리팀)에서 개별 매장의 입점 / 상품 / 주문 / 고객서비스 관리 및 통합 정산관리</div>
          </div>
        </section>
        <section className='flow'>
          <h2>
            셀러허브의 담당 MD가 노하우와 <br className="screen_mobile"/>데이터를 바탕으로<br/>
            상품 및 쇼핑몰 관리 업무 전반을 <br className="screen_mobile"/>대행해 드려요.
          </h2>
          <picture>
            <source srcSet="/assets/images/partners_flow_m.png" media="(max-width: 820px)" />
            <source srcSet="/assets/images/partners_flow.png" media="(min-width: 819px)" />
            <img alt="flow" src="/assets/images/partners_flow.png"/>
          </picture>
        </section>
        <section className='start_partner'>
          <h2>
            한 번의 상품 등록으로 20여개 <br className="screen_mobile"/>쇼핑몰에 판매하세요.
          </h2>
          <div className="partner_container">
            <div className="content_wrapper open_market">
              <div className="column">오픈마켓</div>
              <div className="partners">
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-11st.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-auction.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-lately.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-gmarket.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-lotteon.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-coupang.png"/></span>
              </div>
            </div>
            <div className="content_wrapper general_market">
              <div className="column">종합몰</div>
              <div className="partners">
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-ssg.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-lottehome.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-home-shopping.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-w-shopping.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-cj-onstyle.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-gs-shop.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-sk-store.png"/></span>
              </div>
            </div>
            <div className="content_wrapper special_market">
              <div className="column">전문몰</div>
              <div className="partners">
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-fashionplus.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-halfclub-bk.png"/></span>
              </div>
            </div>
              {/* <div className="box1">오픈마켓</div>
              <div className="box2">종합몰</div>
              <div className="box3">전문몰</div>
              <div className="box4">
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-11st.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-auction.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-lately.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-gmarket.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-lotteon.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-coupang.png"/></span>
              </div>
              <div className="box5">
              <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-ssg.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-lottehome.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-home-shopping.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-w-shopping.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-cj-onstyle.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-gs-shop.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-sk-store.png"/></span>
              </div>
              <div className="box6">
              <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-fashionplus.png"/></span>
                <span className="img-wrapper"><img alt="partner" src="/assets/images/logo-halfclub-bk.png"/></span>
              </div> */}
          </div>
          <div className='info_desc'>
            * 패션 카테고리 한정이에요.<br/>
            * 브랜드 쿠폰을 지원해 드려요
          </div>
          <div className='contact_box'>
            <h2>지금 문의하세요.</h2>
            <div className='contact_wrapper'>
              <span>
                셀러허브 대형파트너사 영업팀
              </span>
              <span>
                sales_inc@sellerhub.co.kr
              </span>
            </div>
          </div>
        </section>
      </div>

    </div>
  )
}
export default Partners;